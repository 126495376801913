module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://gatsby.lt/icecode/graphql","type":{"MediaItem":{"createFileNodes":true,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5LHD7PD","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Icecode.lt","short_name":"Icecode","start_url":"/","background_color":"#ffffff","theme_color":"#e1f2f5","display":"minimal-ui","icon":"src/images/icon/icecode-72x72.png","icons":[{"src":"src/images/icon/icecode-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/icon/icecode-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/icon/icecode-128x128.png","sizes":"128x128","type":"image/png"},{"src":"src/images/icon/icecode-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/icon/icecode-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/images/icon/icecode-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/icon/icecode-384x384.png","sizes":"384x384","type":"image/png"},{"src":"src/images/icon/icecode-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"506fe343cd21bf160578bd8d7a94622d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
